import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getKelas, getSemester } from "../../redux/actions/appAction";
import { checkIsOrtu } from "../../utils/roleUser";
import { getAnak, setAnak } from "../../redux/actions/anakAction";
import { Link } from "react-router-dom";

const FilterUjianSiswa = ({ onFilterChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataSemester, setDataSemester] = useState([]);
  const { kelas } = useSelector(({ app }) => app);
  const { dataAnak, selectedAnak } = useSelector(({ anak }) => anak);
  const { user } = useSelector(({ user }) => user);
  const [filter, setFilter] = useState({
    kelasdetail_id: "",
    semester_id: "",
    status_ujian: "",
    user_id: "",
  });
  const isOrtu = checkIsOrtu(user?.user_roleid);
  useEffect(() => {
    if (selectedAnak) {
      setFilter((pref) => ({
        ...pref,
        user_id: selectedAnak?.user_id,
      }));
    }
  }, [selectedAnak]);

  useEffect(() => {
    if (isOrtu) {
      dispatch(getAnak()).then((res) => {
        if (
          (selectedAnak === null || selectedAnak === undefined) &&
          res?.value
        ) {
          dispatch(setAnak(res?.value[0]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOrtu]);

  useEffect(() => {
    if (!isOrtu) {
      dispatch(getKelas());
    } else if (selectedAnak) {
      dispatch(getKelas({ user_id: selectedAnak?.user_id ?? "" }));
    }
  }, [dispatch, isOrtu, selectedAnak]);

  useEffect(() => {
    if (filter?.kelasdetail_id) {
      const params = {
        kelasdetail_id: filter.kelasdetail_id,
      };
      if (isOrtu) params.user_id = selectedAnak?.user_id;
      getSemester(params).then((res) => setDataSemester(res));
    }
  }, [filter?.kelasdetail_id, dispatch, selectedAnak, isOrtu]);

  useEffect(() => {
    if (!isOrtu || (isOrtu && selectedAnak)) {
      onFilterChange(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, isOrtu]);

  const handleChange = ({ target: { value, name } }) => {
    setFilter((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  const handleChangeAnak = (e) => {
    let val = e?.target?.value;
    let anak = dataAnak?.filter(
      (item) => parseInt(item.user_id) === parseInt(val)
    );
    if (anak) {
      return dispatch(setAnak(anak[0]));
    }
    dispatch(setAnak(null));
  };

  return (
    <div className="col-lg-12">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">{t("ujian_saya")}</h4>
            <Link
              to="/"
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            {checkIsOrtu(user?.user_roleid) ? (
              <div className="col-lg-3">
                <label htmlFor="anak" className="control-label">
                  {t("anak")} :
                </label>
                <select
                  onChange={handleChangeAnak}
                  id="anak"
                  className="form-control form-select"
                  value={selectedAnak?.user_id}
                >
                  {dataAnak?.map((item) => (
                    <option key={item.user_id} value={item.user_id}>
                      {item.user_nama}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            <div className={isOrtu ? "col-lg-3" : "col-lg-4"}>
              <label htmlFor="kelas" className="control-label">
                {t("kelas")} :
              </label>
              <select
                onChange={handleChange}
                id="kelas"
                className="form-control form-select"
                value={filter.kelasdetail_id}
                name="kelasdetail_id"
              >
                <option value="">
                  --
                  {t("pilih_params", {
                    params: t("kelas"),
                  })}
                  --
                </option>
                {kelas?.map((item) => (
                  <option value={item.kelasdetail_id} key={item.kelasdetail_id}>
                    {item.kelasdetail_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className={isOrtu ? "col-lg-3" : "col-lg-4"}>
              <label htmlFor="semester_id" className="control-label">
                {t("semester")} :
              </label>
              <select
                onChange={handleChange}
                id="semester_id"
                className="form-control form-select"
                value={filter.semester_id}
                name="semester_id"
              >
                <option value="">
                  --
                  {t("pilih_params", {
                    params: t("semester"),
                  })}
                  --
                </option>
                {dataSemester?.map((item) => (
                  <option value={item.semester_id} key={item.semester_id}>
                    Semester {item.semester_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className={isOrtu ? "col-lg-3" : "col-lg-4"}>
              <label htmlFor="status_ujian" className="control-label">
                {t("status_ujian")} :
              </label>
              <select
                onChange={handleChange}
                id="status_ujian"
                className="form-control form-select"
                value={filter.status_ujian}
                name="status_ujian"
              >
                <option value="">
                  --
                  {t("pilih_params", {
                    params: t("status_ujian"),
                  })}
                  --
                </option>
                <option value="dimulai">{t("dimulai")}</option>
                <option value="selesai">{t("selesai")}</option>
                <option value="akan-datang">{t("akan-datang")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterUjianSiswa;

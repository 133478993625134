import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  getBahasaLibrary,
  getKelasLibrary,
  getMapelLibrary,
} from "../../redux/actions/libraryAction";

function FilterLibrary({
  tahun1,
  tahun2,
  bahasa,
  kelas,
  search,
  handleChange,
  getData,
  handleChangeSearch,
  mapel,
  sortBy,
}) {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const [dataBahasa, setDataBahasa] = useState([]);
  const [dataKelas, setDataKelas] = useState([]);
  const [dataMapel, setDataMapel] = useState([]);
  const { user } = useSelector(({ user }) => user);
  useEffect(() => {
    if (user) {
      const sekolahId = user?.user_sekolahid;
      getBahasaLibrary(user?.user_id).then((res) => setDataBahasa(res?.data));
      getKelasLibrary(sekolahId).then((res) => setDataKelas(res?.data));
      getMapelLibrary(sekolahId).then((res) => setDataMapel(res?.data));
    }
  }, [user]);
  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
  };
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title">
          <h4 className="card-title">Filter</h4>
        </div>
      </div>
      <div className="iq-card-body ">
        <div className="d-flex align-items-end row  mb-3">
          <div className="form-group mb-3">
            <label htmlFor="search">{t("cari_koleksi")} :</label>
            <form onSubmit={handleSubmit} className="position-relative">
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control todo-search"
                  id="search"
                  placeholder={t("cari") + "..."}
                  value={search}
                  name="search"
                  onChange={handleChangeSearch}
                />
              </div>
            </form>
          </div>
          <div className="form-group  col-lg-6 ">
            <label htmlFor="tahun1">{t("tahun")} :</label>
            <select
              id="tahun1"
              className="form-control form-select"
              value={tahun1}
              name="tahun1"
              onChange={handleChange}
            >
              <option value="">{t("dari")}</option>
              {[...Array(10).keys()].map((item) => (
                <option key={crypto.randomUUID()} value={year - item}>
                  {year - item}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-lg-6">
            <select
              className="form-control form-select"
              value={tahun2}
              name="tahun2"
              onChange={handleChange}
            >
              <option value="">{t("sampai")}</option>
              {[...Array(10).keys()].map((item) => (
                <option key={crypto.randomUUID()} value={year - item}>
                  {year - item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group mb-3">
          <label>{t("bahasa")} :</label>
          <select
            className="form-control form-select"
            value={bahasa}
            onChange={handleChange}
            name="bahasa"
          >
            <option value="">
              {t("pilih_params", { params: t("bahasa") })}
            </option>
            {dataBahasa?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <label>{t("kelas")} :</label>
          <select
            className="form-control form-select"
            value={kelas}
            onChange={handleChange}
            name="kelas"
          >
            <option value="">
              {t("pilih_params", { params: t("kelas") })}
            </option>
            {dataKelas?.map((item) => (
              <option value={item.kelas_id} key={item.kelas_id}>
                {t("kelas")} {item.kelas_nama}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <label>{t("mata_pelajaran")} :</label>
          <select
            className="form-control form-select"
            value={mapel}
            onChange={handleChange}
            name="mapel"
          >
            <option value="">
              {t("pilih_params", { params: t("mata_pelajaran") })}
            </option>
            {dataMapel?.map((item) => (
              <option value={item.mapel_id} key={item.mapel_id}>
                {item.mapel_nama}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="filter">Filter :</label>
          <select
            className="form-control form-select"
            id="filter"
            name="sort_by"
            value={sortBy}
            onChange={handleChange}
          >
            <option value="">Semua</option>
            <option value="populer">Populer</option>
            <option value="terbaru">Terbaru</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default FilterLibrary;

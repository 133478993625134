import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { likeBook } from "../../redux/actions/libraryAction";
import { ModalDetailBuku } from "../modal";
import Swal from "sweetalert2";
import { LIKE_LIBRARY } from "../../redux/constant";

function BookPotraitCard({
  judul,
  cover,
  year,
  author,
  isLike,
  code,
  view,
  fileUrl,
  kategori,
  like,
  params,
  getUsertData,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const handleLike = (code) => {
    setLoading(true);
    likeBook(user?.user_id, code).then((res) => {
      dispatch({
        type: LIKE_LIBRARY,
        payload: res?.data,
      });
      getUsertData();
      setLoading(false);
    });
  };
  const handleCopy = async (link) => {
    try {
      await navigator.clipboard.writeText(link);
      Swal.fire({
        title: t("berhasil"),
        text: t("berhasil_dicopy"),
        icon: "info",
        confirmButtonText: "Ok",
        confirmButtonColor: "#6fbd44",
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <div className="col-lg-4 col-md-6 col-12">
      <div className="iq-card rounded iq-card-block iq-card-stretch iq-card-height">
        <div className="event-images">
          <a href={fileUrl} target="_blank" rel="noreferrer">
            <img
              src={cover}
              className="img-fluid w-100 img-fit min-h-300 bg-grey"
              alt="cover buku"
              onError={(e) => {
                e.target.src = require("../../assets/images/default_cover.webp");
                e.target.onError = null;
              }}
            />
          </a>
        </div>
        <div className="iq-card-body">
          <h5>{judul}</h5>
          <h6 className="text-muted fst-italic font-size-12 text-capitalize mb-2">
            ({kategori})
          </h6>
          <div className="row mb-2">
            <div className="col-sm-1">
              <i className="ri-user-2-fill"></i>
            </div>
            <div className="col-sm-11">
              <span className="elipsis-1-line d-block">{author}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-2 text-danger">
              <i className="ri-heart-fill mr-1"></i>
              <span>{like}</span>
            </div>
            <p className="m-0">{year}</p>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <div className="gap-2 d-flex">
              <button
                disabled={loading}
                className="btn btn-danger btn-sm text-white"
                onClick={() => handleLike(code)}
              >
                <i
                  className={`${
                    isLike ? "ri-heart-fill" : "ri-heart-line"
                  } m-0`}
                ></i>
              </button>
              <button
                className="btn btn-primary btn-sm text-white"
                onClick={() => handleCopy(fileUrl)}
              >
                <i className="ri-link-m m-0"></i>
              </button>
              <button
                className="btn btn-info btn-sm text-white"
                onClick={() => setShowDetail(true)}
              >
                <i className="ri-information-line m-0"></i>
              </button>
            </div>
            <div className=" line-height">
              <span>
                {" "}
                {t("dibaca")} : {t("no_kali", { no: view })}
              </span>
            </div>
          </div>
        </div>
      </div>
      {showDetail && (
        <ModalDetailBuku
          show={showDetail}
          bookId={code}
          handleClose={() => setShowDetail(false)}
        />
      )}
    </div>
  );
}

export default BookPotraitCard;

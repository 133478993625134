import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getBookCategory } from "../../redux/actions/libraryAction";

const SortLibrary = ({
  baca,
  favorite,
  kembali,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [category, setCategory] = useState([]);
  useEffect(() => {
    if (user?.user_id) {
      getBookCategory(user?.user_id).then((res) => setCategory(res?.data));
    }
  }, [user]);

  const handleCategoryChange = (e) => {
    const value = parseInt(e.target.value);
    const checked = selectedCategory.filter((item) => item === value);
    if (checked.length <= 0) {
      setSelectedCategory((prev) => [...prev, value]);
    } else {
      setSelectedCategory((prev) => prev.filter((item) => item !== value));
    }
  };
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title">
          <h4 className="card-title">{t("urutkan")}</h4>
        </div>
      </div>
      <div className="iq-card-body">
        <Accordion defaultActiveKey="0" className="accordion-sort-library">
          <Accordion.Item eventKey="1" className="border-0">
            <Accordion.Header>{t("kategori")}</Accordion.Header>
            <Accordion.Body className="p-0">
              {category?.map((item) => (
                <div
                  className="custom-control custom-checkbox custom-control-inline mb-2"
                  key={item.id}
                >
                  <input
                    name="history"
                    type="checkbox"
                    className="custom-control-input"
                    id={item.id}
                    value={item.id}
                    onChange={handleCategoryChange}
                    checked={selectedCategory.includes(item.id)}
                  />
                  <label className="custom-control-label" htmlFor={item.id}>
                    {item.category} ({item.buku_count})
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default SortLibrary;

import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { EXCEL_EXT, MS_EXT, PPT_EXT } from "../../config/fileType";

function ModalFile({ url, show, type, handleClose }) {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12">
      <Modal
        size="lg"
        fullscreen
        show={show}
        onHide={handleClose}
        id="ModalFile"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("preview")}</h5>
        </Modal.Header>
        <Modal.Body>
          {type === "application/pdf" ? (
            <iframe src={url} title="preview" height="100%" width="100%" />
          ) : PPT_EXT.includes(type) ||
            MS_EXT.includes(type) ||
            EXCEL_EXT.includes(type) ? (
            <div className="embed-responsive embed-responsive-ppt">
              <iframe
                className="embed-responsive-item ppt"
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                allowFullScreen
                title="materi video"
              ></iframe>
            </div>
          ) : (
            <img
              src={url}
              alt="preview file"
              className="w-100 h-100 contain-image"
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalFile;

import React from "react";

import { Wrapper } from "../../components";

function Pembayaran() {
  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Pilih Semester</h4>
                </div>
              </div>
              <div className="iq-card-body">
                <form className="form-horizontal" action="/action_page.php">
                  <div className="row">
                    <label
                      htmlFor="semester"
                      className="control-label col-sm-3 align-self-center mb-0"
                    >
                      Semester :
                    </label>
                    <div className="col-sm-9">
                      <select className="form-control form-select">
                        <option value="">--Pilih Semester--</option>
                        <option value="1">Semester Ganjil (1 MIPA)</option>
                        <option value="2">Semester Genap (1 MIPA)</option>
                        <option value="3">Semester Ganjil (2 MIPA)</option>
                        <option value="4">Semester Genap (2 MIPA)</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Biaya Semester</h4>
                </div>
              </div>
              <div className="iq-card-body">
                <table className="table table-hover">
                  <thead className="table-primary">
                    <tr className="text-white">
                      <th className="bg-transparent" scope="col" width="25%">
                        Kategori Pembayaran
                      </th>
                      <th className="bg-transparent" scope="col" width="25%">
                        Biaya
                      </th>
                      <th className="bg-transparent" scope="col" width="25%">
                        Sudah Dibayar
                      </th>
                      <th className="bg-transparent" scope="col" width="10%">
                        Status
                      </th>
                      <th
                        className="bg-transparent text-center"
                        scope="col"
                        width="15%"
                      >
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Biaya Tetap</th>
                      <td>Rp 1.006.000,00</td>
                      <td>Rp 1.006.000,00</td>
                      <td>
                        <span className="badge badge-danger">Unpaid</span>
                      </td>
                      <td className="text-center">
                        <button type="button" className="btn btn-link">
                          <i className="ri-printer-line"></i> Print
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Biaya DPPT Awal</th>
                      <td>Rp 1.006.000,00</td>
                      <td>Rp 1.006.000,00</td>
                      <td>
                        <span className="badge badge-success">Paid</span>
                      </td>
                      <td className="text-center">
                        <button type="button" className="btn btn-link">
                          <i className="ri-printer-line"></i> Print
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Biaya Lain-lain</th>
                      <td>Rp 1.006.000,00</td>
                      <td>Rp 1.006.000,00</td>
                      <td>
                        <span className="badge badge-success">Paid</span>
                      </td>
                      <td className="text-center">
                        <button type="button" className="btn btn-link">
                          <i className="ri-printer-line"></i> Print
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Pembayaran;

export const LOGIN = "LOGIN";
export const LOGIN_GOOGLE = "LOGIN_GOOGLE";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_KELAS = "GET_KELAS";
export const GET_PELAJARAN = "GET_PELAJARAN";
export const GET_SEMESTER = "GET_SEMESTER";
export const GET_TOPIK = "GET_TOPIK";
export const GET_OPTION_TOPIK = "GET_OPTION_TOPIK";
export const GET_SOAL = "GET_SOAL";
export const GET_ANAK = "GET_ANAK";
export const GET_LIBRARY = "GET_LIBRARY";
export const SET_SELECTED_ANAK = "SET_SELECTED_ANAK";
export const GET_NILAI = "GET_NILAI";
export const GET_BANK_SOAL = "GET_BANK_SOAL";
export const GET_DRIVE = "GET_DRIVE";
export const GET_DRIVE_DETAIL = "GET_DRIVE_DETAIL";
export const GET_NOTIF_GROUP = "GET_NOTIF_GROUP";
export const GET_DETAIL_NOTIF_GROUP = "GET_DETAIL_NOTIF_GROUP";
export const GET_DETAIL_NOTIF = "GET_DETAIL_NOTIF";
export const SET_TOPIK = "SET_TOPIK";
export const SET_DETAIL_TOPIK = "SET_DETAIL_TOPIK";
export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_MARKED = "GET_COURSE_MARKED";
export const GET_COURSE_USER = "GET_COURSE_USER";
export const GET_COURSE_PAYMENT = "GET_COURSE_PAYMENT";
export const GET_UJIAN = "GET_UJIAN";
export const LIKE_LIBRARY = "LIKE_LIBRARY";
export const RESET_LIBRARY = "RESET_LIBRARY";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonRaporCambridge,
  ButtonRaporMerdeka,
  ButtonRaporP4,
  ButtonRaporTK,
  Wrapper,
} from "../../components";
import { getAnak, setAnak } from "../../redux/actions/anakAction";
import { getKelas } from "../../redux/actions/appAction";
import { getRapor, getSemesterUser } from "../../redux/actions/raporAction";
import { checkIsOrtu } from "../../utils/roleUser";
import { Link } from "react-router-dom";

function Rapor() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { kelas } = useSelector(({ app }) => app);
  const { user } = useSelector(({ user }) => user);
  const { dataAnak, selectedAnak } = useSelector(({ anak }) => anak);
  const [errors, setErrors] = useState(null);
  const [semester, setSemester] = useState([]);
  const [dataRapor, setDataRapor] = useState(null);
  const [params, setParams] = useState({
    kelas_id: "",
    semester_id: "",
  });
  useEffect(() => {
    if (checkIsOrtu(user?.user_roleid)) {
      dispatch(getAnak()).then((res) => {
        if (
          (selectedAnak === null || selectedAnak === undefined) &&
          res?.value
        ) {
          dispatch(setAnak(res?.value[0]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.user_roleid]);

  useEffect(() => {
    if (!checkIsOrtu(user?.user_roleid)) {
      dispatch(getKelas());
    } else if (selectedAnak) {
      dispatch(getKelas({ user_id: selectedAnak?.user_id ?? "" }));
    }
  }, [dispatch, selectedAnak, user?.user_roleid]);

  useEffect(() => {
    setSemester([]);
    function getData() {
      let data = {
        user_id: checkIsOrtu(user?.user_roleid)
          ? selectedAnak?.user_id
          : user?.user_id,
      };
      getSemesterUser(data).then((res) => setSemester(res?.data));
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnak?.user_id]);

  useEffect(() => {
    if (params.kelas_id === "" && params.semester_id === "") return;
    let data = new Map(Object.entries(params));
    let user_id = checkIsOrtu(user?.user_roleid)
      ? selectedAnak?.user_id
      : user?.user_id;
    data.set("user_id", user_id);
    setErrors([]);

    getRapor(data)
      .then((res) => setDataRapor(res))
      .catch(({ response }) => {
        let error = response?.data;
        if (response?.status === 400) setErrors(error?.errors);
      });
  }, [user, selectedAnak?.user_id, params]);

  const handleChangeAnak = (e) => {
    let val = e?.target?.value;
    let anak = dataAnak?.filter(
      (item) => parseInt(item.user_id) === parseInt(val)
    );
    if (anak) return dispatch(setAnak(anak[0]));
    dispatch(setAnak(null));
  };

  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  return (
    <Wrapper title={t("rapor")}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
                  <h4 className="card-title">{t("filter_kelas")}</h4>
                  <Link
                    to={`/`}
                    className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                  >
                    <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
                  </Link>
                </div>
              </div>
              <div className="iq-card-body">
                <div className="row">
                  {checkIsOrtu(user?.user_roleid) ? (
                    <div className="col-12 col-md-4">
                      <label htmlFor="anak" className="control-label">
                        {t("anak")} :
                      </label>
                      <select
                        onChange={handleChangeAnak}
                        id="anak"
                        className="form-control form-select"
                        value={selectedAnak?.user_id}
                      >
                        {dataAnak?.map((item) => (
                          <option key={item.user_id} value={item.user_id}>
                            {item.user_nama}
                          </option>
                        ))}
                      </select>
                      {errors?.user ? (
                        <span className="invalid-feedback d-block">
                          {errors?.user[0]}
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  <div
                    className={`col-12 ${
                      checkIsOrtu(user?.user_roleid) ? "col-md-4" : "col-md-6"
                    }`}
                  >
                    <label htmlFor="kelas_id" className="control-label">
                      {t("kelas")} :
                    </label>
                    <select
                      className="form-control form-select"
                      name="kelas_id"
                      id="kelas_id"
                      onChange={handleChange}
                      value={params.kelas_id}
                    >
                      <option value="">
                        --{t("pilih_params", { params: t("kelas") })}--
                      </option>
                      {kelas?.map((item) => (
                        <option
                          value={item.kelasdetail_id}
                          key={item.kelasdetail_id}
                        >
                          {item.kelasdetail_nama}
                        </option>
                      ))}
                    </select>
                    {errors?.kelas ? (
                      <span className="invalid-feedback d-block">
                        {errors?.kelas[0]}
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={`col-12 ${
                      checkIsOrtu(user?.user_roleid) ? "col-md-4" : "col-md-6"
                    }`}
                  >
                    <label htmlFor="semester_id" className="control-label">
                      Semester :
                    </label>
                    <select
                      className="form-control form-select"
                      name="semester_id"
                      id="semester_id"
                      onChange={handleChange}
                      value={params.semester_id}
                    >
                      <option value="">
                        --{t("pilih_params", { params: "Semester" })}--
                      </option>
                      {semester?.map((item) => (
                        <option value={item.semester_id} key={item.semester_id}>
                          Semester {item.semester_nama}
                        </option>
                      ))}
                    </select>
                    {errors?.semester ? (
                      <span className="invalid-feedback d-block">
                        {errors?.semester}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-body">
                <table>
                  <tbody>
                    <tr>
                      <td width="40%" className="font-weight-bold">
                        {t("nama")}{" "}
                      </td>
                      <td className="font-weight-bold">
                        : {dataRapor?.user?.user_nama}
                      </td>
                    </tr>
                    <tr>
                      <td width="40%" className="font-weight-bold">
                        {t("kelas")}{" "}
                      </td>
                      <td className="font-weight-bold">
                        : {dataRapor?.kelas?.kelasdetail_nama}
                      </td>
                    </tr>
                    <tr>
                      <td width="40%" className="font-weight-bold">
                        Semester{" "}
                      </td>
                      <td className="font-weight-bold">
                        :
                        <span className="badge badge-pill badge-success ms-1">
                          {dataRapor?.semester?.semester_alias}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <table className="table table-striped table-bordered table-hover">
                  <thead className="table-primary">
                    <tr className="text-white">
                      <th className="bg-transparent" width="5%">
                        No
                      </th>
                      <th className="bg-transparent" width="20%">
                        {t("jenis_rapor")}
                      </th>
                      <th className="bg-transparent">{t("aksi")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!dataRapor || dataRapor?.data?.length <= 0 ? (
                      <tr className="text-center">
                        <td colSpan={3}>
                          {t("data_params_kosong", { params: t("rapor") })}
                        </td>
                      </tr>
                    ) : (
                      dataRapor?.data?.map((item, idx) => (
                        <tr key={crypto.randomUUID()}>
                          <td>{idx + 1}</td>
                          <td>{item.jenis_rapor}</td>
                          <td className="d-flex gap-2">
                            {item?.jenis_rapor === "TK/Paud" ? (
                              <ButtonRaporTK
                                kelas={item.data?.id_fase}
                                kelas_detail={item.data?.id_kelompok}
                                sekolah={dataRapor?.user?.sekolah?.sekolah_id}
                                semester={item?.data?.semester}
                                status={item?.data?.is_tampil}
                                tahun={item?.data?.tahun_ajar}
                                user_id={item?.data?.user_id}
                              />
                            ) : item?.jenis_rapor === "Projek P4" ? (
                              <ButtonRaporP4
                                kelas={item.data?.kelas}
                                kelas_detail={item.data?.kelas_detail}
                                sekolah={dataRapor?.user?.sekolah?.sekolah_id}
                                semester={item?.data?.semester}
                                status={item?.data?.is_tampil}
                                tahun={item?.data?.tahun_ajar}
                                user_id={item?.data?.user_id}
                              />
                            ) : item?.jenis_rapor === "Cambridge" ? (
                              <ButtonRaporCambridge
                                kelas={item.data?.kelas}
                                kelas_detail={item.data?.kelas_detail}
                                sekolah={dataRapor?.user?.sekolah?.sekolah_id}
                                semester={item?.data?.semester}
                                status={item?.data?.is_tampil}
                                tahun={item?.data?.tahun_ajar}
                                user_id={item?.data?.user_id}
                              />
                            ) : item?.jenis_rapor === "Merdeka" ? (
                              <ButtonRaporMerdeka
                                kelas={item.data?.kelas}
                                kelas_detail={item.data?.kelas_detail}
                                sekolah={dataRapor?.user?.sekolah?.sekolah_id}
                                semester={item?.data?.semester}
                                status={item?.data?.is_tampil}
                                tahun={item?.data?.tahun_ajar}
                                user_id={item?.data?.user_id}
                              />
                            ) : null}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Rapor;

import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  getAbsensiKelas,
  getAbsensiSemester,
  getDetailPekanAbsensiSiswa,
  getPekanAbsensi,
} from "../../../redux/actions/absenAction";
import { getAnak, setAnak } from "../../../redux/actions/anakAction";
import { checkIsOrtu } from "../../../utils/roleUser";
import { Link } from "react-router-dom";

function WeeklySiswa() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(({ user }) => user);
  const { dataAnak, selectedAnak } = useSelector(({ anak }) => anak);
  const [kelas, setKelas] = useState([]);
  const [semester, setSemester] = useState([]);
  const [selectedPekan, setSelectedPekan] = useState("");
  const [pekan, setPekan] = useState([]);
  const [detailRapor, setDetailRapor] = useState([]);
  const [params, setParams] = useState({
    pekan_id: "",
    user_id: "",
    kelasdetail_id: "",
    semester_id: "",
  });
  function checkProperties(obj) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === "") return false;
    }
    return true;
  }
  useEffect(() => {
    if (checkIsOrtu(user?.user_roleid)) {
      dispatch(getAnak()).then((res) => {
        if (
          (selectedAnak === null || selectedAnak === undefined) &&
          res?.value
        ) {
          dispatch(setAnak(res?.value[0]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);
  useEffect(() => {
    function getData() {
      if (checkIsOrtu(user?.user_roleid)) {
        getAbsensiKelas({
          userkelas_userid: selectedAnak?.user_id,
        }).then((res) => setKelas(res));
      } else {
        getAbsensiKelas().then((res) => setKelas(res));
      }
    }
    getData();
  }, [selectedAnak?.user_id, user?.user_roleid]);
  useEffect(() => {
    setSemester([]);
    function getData() {
      let data = { kelasdetail_id: params.kelasdetail_id };
      if (checkIsOrtu(user?.user_roleid)) data.user_id = selectedAnak?.user_id;
      getAbsensiSemester(data).then((res) => setSemester(res));
    }
    if (params.kelasdetail_id) {
      getData();
    }
  }, [params.kelasdetail_id, selectedAnak?.user_id, user?.user_roleid]);
  const getPekan = () => {
    const data = {
      kelasdetail_id: params.kelasdetail_id,
      semester_id: params.semester_id,
      user_id: checkIsOrtu(user?.user_roleid)
        ? selectedAnak?.user_id
        : user?.user_id,
    };
    getPekanAbsensi(data).then((res) => setPekan(res));
  };
  useEffect(() => {
    setPekan([]);
    if (params.kelasdetail_id && params.semester_id) {
      getPekan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  useEffect(() => {
    function getData() {
      getDetailPekanAbsensiSiswa(params).then((res) => {
        setDetailRapor(res);
      });
    }
    let check = checkProperties(params);
    if (check) {
      getData();
    }
  }, [params]);

  const handleChange = ({ target: { value, name } }) => {
    if (name === "pekan_id" && value) {
      let cek = pekan?.filter(
        (item) => parseInt(item.pekan_id) === parseInt(value)
      );
      setSelectedPekan(cek[0]);
    } else {
      setSelectedPekan(null);
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log("set kelas id", checkIsOrtu(user?.user_roleid));
    if (checkIsOrtu(user?.user_roleid)) {
      setParams((pref) => ({
        ...pref,
        user_id: selectedAnak?.user_id,
      }));
    } else {
      console.log("set anak", user);
      setParams((pref) => ({
        ...pref,
        user_id: user?.user_id,
      }));
    }
  }, [user, selectedAnak]);

  const printRowTable = (data, gabungan, id) => (
    <tr key={data.weekly_kelasmapelid}>
      <td>{gabungan ? "" : id + 1}</td>
      <td>{data?.kelas_mapel?.mapel?.mapel_nama}</td>
      <td>{data?.weekly_catatan_guru}</td>
    </tr>
  );
  const handleChangeAnak = (e) => {
    let val = e?.target?.value;
    let anak = dataAnak?.filter(
      (item) => parseInt(item.user_id) === parseInt(val)
    );
    if (anak) {
      return dispatch(setAnak(anak[0]));
    }
    dispatch(setAnak(null));
  };

  return (
    <div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">Filter {t("kelas")}</h4>
            <Link
              to={`/`}
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            {checkIsOrtu(user?.user_roleid) ? (
              <div className="col-12 col-md-3">
                <label htmlFor="anak" className="control-label">
                  {t("anak")} :
                </label>
                <select
                  onChange={handleChangeAnak}
                  id="anak"
                  className="form-control form-select"
                  value={selectedAnak?.user_id}
                >
                  {dataAnak?.map((item) => (
                    <option key={item.user_id} value={item.user_id}>
                      {item.user_nama}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            <div
              className={`col-12 ${
                checkIsOrtu(user?.user_roleid) ? "col-md-3" : "col-md-4"
              }`}
            >
              <label htmlFor="kelasdetail_id" className="control-label">
                {t("kelas")} :
              </label>
              <select
                id="kelasdetail_id"
                className="form-control form-select"
                name="kelasdetail_id"
                onChange={handleChange}
                value={params.kelasdetail_id}
              >
                <option value="">
                  --{t("pilih_params", { params: t("kelas") })}--
                </option>
                {kelas?.map((e) => (
                  <option value={e.kelasdetail_id} key={e.kelasdetail_id}>
                    {e.kelasdetail_nama}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`col-12 ${
                checkIsOrtu(user?.user_roleid) ? "col-md-3" : "col-md-4"
              }`}
            >
              <label htmlFor="semester" className="control-label">
                Semester :
              </label>
              <select
                className="form-control form-select"
                name="semester_id"
                id="semester_id"
                onChange={handleChange}
                value={params.semester_id}
              >
                <option value="">
                  --{t("pilih_params", { params: "Semester" })}--
                </option>
                {semester?.map((e) => (
                  <option value={e.semester_id} key={e.semester_id}>
                    Semester {e.semester_nama}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`col-12 ${
                checkIsOrtu(user?.user_roleid) ? "col-md-3" : "col-md-4"
              }`}
            >
              <label htmlFor="semester" className="control-label">
                {t("pekan")} :
              </label>
              <select
                className="form-control form-select"
                name="pekan_id"
                value={params.pekan_id}
                onChange={handleChange}
              >
                <option value="">
                  --{t("pilih_params", { params: t("pekan") })}--
                </option>
                {pekan?.map((item) => (
                  <option value={item.pekan_id} key={item.pekan_id}>
                    {item.pekan_nama}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="iq-card">
        <div className="iq-card-body">
          <table className="mb-5">
            <tbody>
              <tr>
                <td width="40%" className="fw-bold">
                  {t("nama")}
                </td>
                <td className="fw-bold">: &nbsp; {user?.user_nama}</td>
              </tr>
              <tr>
                <td width="40%" className="fw-bold">
                  {t("pekan")}
                </td>
                <td className="fw-bold">
                  : &nbsp; {selectedPekan?.pekan_nama}
                </td>
              </tr>
              <tr>
                <td width="40%" className="fw-bold">
                  {t("tanggal")}
                </td>
                <td className="fw-bold">
                  : &nbsp;{" "}
                  <span className="badge badge-pill badge-success">
                    {selectedPekan &&
                      `${moment(
                        selectedPekan?.pekan_mulai?.substr(0, 10)
                      ).format("DD MMMM YYYY")} - ${moment(
                        selectedPekan?.pekan_akhir?.substr(0, 10)
                      ).format("DD MMMM YYYY")}`}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {detailRapor?.length <= 0 ? (
            <p className="text-center">{t("data_tdk_ditemukan")}</p>
          ) : (
            detailRapor?.weekly_mapel?.map((item, idx) => (
              <div className="table-responsive" key={crypto.randomUUID()}>
                <table id="datatable" className="table table-bordered">
                  <thead className="table-primary text-white">
                    <tr>
                      <th className="bg-transparent" width="5%">
                        No
                      </th>
                      <th className="bg-transparent" width="20%">
                        {t("mata_pelajaran")}
                      </th>
                      <th className="bg-transparent" width="10%">
                        {t("kegiatan")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.group_mapel?.length <= 0 ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          {t("data_params_kosong", { params: t("rapor") })}
                        </td>
                      </tr>
                    ) : (
                      item.group_mapel?.map((e, id) => {
                        if (e.mapel_gabungan) {
                          return (
                            <Fragment key={crypto.randomUUID()}>
                              <tr>
                                <td>{id + 1}</td>
                                <td>{e?.group_name}</td>
                                <td></td>
                              </tr>
                              {e?.group_data?.map((data) =>
                                printRowTable(data, e.mapel_gabungan, id)
                              )}
                            </Fragment>
                          );
                        } else {
                          return printRowTable(e, e.mapel_gabungan, id);
                        }
                      })
                    )}
                  </tbody>
                </table>
              </div>
            ))
          )}
          {detailRapor?.weekly_walas ? (
            <div className="table-responsive" key={crypto.randomUUID()}>
              <p className="fw-bold m-0">{t("catatan_walas")}</p>
              <div className="table-responsive">
                <table id="datatable" className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>{detailRapor?.weekly_walas?.weekly_catatan_walas}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default WeeklySiswa;

import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";

import { PageLoader } from "../../../assets/images";
import {
  ActionMateri,
  MapelInfo,
  ModalDiskusi,
  SortTopik,
  TambahLiveClass,
  TambahMateri,
  TambahTopik,
  TopikCard,
  VerifyBagikanModul,
} from "../../../components";
import ShareKelas from "../../../components/modal/ShareKelas";
import TambahTugas from "../../../components/modal/TambahTugas";
import { getListSiswa } from "../../../redux/actions/pelajaranAction";
import {
  convertTopik,
  getTopik,
  setTopik,
  sortTopik,
} from "../../../redux/actions/topikAction";
import { checkIsGuru, checkIsOrtu } from "../../../utils/roleUser";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function Materi({ detailKelas, loadingDetail }) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const sortingRef = useRef(false);
  const { id } = useParams();
  const { selectedAnak } = useSelector(({ anak }) => anak);
  const [loading, setLoading] = useState(true);
  const [showModalTopik, setShowModalTopik] = useState(false);
  const [showModalMateri, setShowModalMateri] = useState(false);
  const [showAddLiveClass, setShowAddLiveClass] = useState(false);
  const [showModalTugas, setShowModalTugas] = useState(false);
  const [showModalDiskusi, setShowModalDiskusi] = useState(false);
  const [showSortTopik, setShowSortTopik] = useState(false);
  const [listSiswa, setListSiswa] = useState([]);
  const topik = useSelector(({ topik }) => topik);
  const { user } = useSelector(({ user }) => user);
  const [editTopik, setEditTopik] = useState(null);
  const [editMateri, setEditMateri] = useState(null);
  const [editTugas, setEditTugas] = useState(null);
  const [editDiskusi, setEditDiskusi] = useState(null);
  const [sortingData, setSortingData] = useState(false);
  const [showShareKelas, setShowShareKelas] = useState(false);
  const [selectedTopik, setSelectedTopik] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [loadingConvert, setLoadingConvert] = useState(false);
  const isGuru = checkIsGuru(user?.user_roleid);

  useEffect(() => {
    if (window.innerWidth >= 992) setSortingData(true);
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 992 && !sortingData) setSortingData(true);
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sortingData]);

  useEffect(() => {
    getDataTopik();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location.search]);
  useEffect(() => {
    if (detailKelas?.kelasmapel_kelasdetailid) {
      let params = new URLSearchParams(location.search);
      let th = params.get("th");
      let smt = params.get("smt");
      let data = {};
      if (th) data["tahunpelajaran_id"] = th;
      if (smt) data["semester_id"] = smt;
      if (checkIsOrtu(user?.user_roleid))
        data["user_id"] = selectedAnak?.user_id;
      getListSiswa(detailKelas?.kelasmapel_kelasdetailid, data)
        .then((res) => {
          setListSiswa(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  }, [detailKelas, location.search, user, selectedAnak]);

  function getDataTopik() {
    setSelectedTopik([]);
    setShowAction(false);
    let params = new URLSearchParams(location.search);
    let th = params.get("th");
    let smt = params.get("smt");
    const data = {
      tahunpelajaran_id: th,
      semester_id: smt,
    };
    if (selectedAnak && checkIsOrtu(user?.user_roleid)) {
      data.user_id = selectedAnak?.user_id;
    }
    dispatch(getTopik(id, data));
  }

  const handleSortTopik = (newTopik) => {
    if (!sortingRef.current) return;
    dispatch(setTopik(newTopik));
    sortTopik({
      id,
      topik_list: newTopik?.map((item, idx) => ({
        topik_id: item.topik_id,
        topik_index: idx + 1,
      })),
    });
  };

  const handleChangeSelectedTopik = (id, checked) => {
    if (checked) return setSelectedTopik((pref) => [...pref, id]);
    setSelectedTopik((pref) => pref.filter((item) => item !== id));
  };

  const handleCheckedAll = ({ target: { checked } }) => {
    if (!checked) return setSelectedTopik([]);
    setSelectedTopik(topik?.topik?.map((item) => item.topik_id));
  };

  const handleConvert = () => {
    const params = new URLSearchParams(location.search);
    const th = params.get("th");
    const smt = params.get("smt");
    const data = {
      user_id: user?.user_id,
      kelasmapel_id: id,
      topik: selectedTopik,
      tahun: th,
      semester: smt,
    };
    setLoadingConvert(true);

    convertTopik(data)
      .then((res) => {
        window.open(res?.path, "_blank");
        setLoadingConvert(false);
      })
      .catch(() => {
        setLoadingConvert(false);
        Swal.fire({
          title: t("gagal"),
          text: t("gagal_convert"),
          icon: "error",
          showConfirmButton: false,
        });
      });
  };

  const handleModalDiskusi = () => setShowModalDiskusi(!showModalDiskusi);
  const handleModalMateri = () => setShowModalMateri(!showModalMateri);
  const handleModalTopik = () => setShowModalTopik(!showModalTopik);
  const handleModalTugas = () => setShowModalTugas(!showModalTugas);
  const handleModalShare = () => setShowShareKelas(!showShareKelas);
  const handleSorting = () => setSortingData(!sortingData);
  const handleToogleAction = () => setShowAction(!showAction);

  return loadingDetail || loading ? (
    <div className="col-12 text-center">
      <img src={PageLoader} alt="loader" style={{ height: "100px" }} />
    </div>
  ) : (
    <div className="tab-pane fade active show" id="materi" role="tabpanel">
      <MapelInfo
        kelas={detailKelas?.detailKelas?.kelasdetail_nama}
        mapel={detailKelas?.mapel?.mapel_nama}
        pengajar={detailKelas?.pengajar}
        semester={detailKelas?.semester_nama}
      />
      {isGuru && (
        <>
          <VerifyBagikanModul idMapel={id} getDataTopik={getDataTopik} />
          <ActionMateri
            handleCheckedAll={handleCheckedAll}
            handleDiskusi={handleModalDiskusi}
            handleMateri={handleModalMateri}
            handleShare={handleModalShare}
            handleConvert={handleConvert}
            handleSorting={handleSorting}
            handleTopik={handleModalTopik}
            handleTugas={handleModalTugas}
            handleAction={handleToogleAction}
            jmlSelectedTopik={selectedTopik?.length}
            sortingData={sortingData}
            topik={topik?.topik}
            showAction={showAction}
            loadingConvert={loadingConvert}
            selectedTopik={selectedTopik}
            getDataTopik={getDataTopik}
          />
        </>
      )}
      {showModalTopik && (
        <TambahTopik
          show={showModalTopik}
          detailKelas={detailKelas}
          dataSiswa={listSiswa}
          editTopik={editTopik}
          handleClose={() => {
            setShowModalTopik(false);
            setEditTopik(null);
          }}
          dataTopik={topik?.topik}
        />
      )}
      {showModalMateri && (
        <TambahMateri
          show={showModalMateri}
          dataTopik={topik}
          dataSiswa={listSiswa}
          handleClose={() => {
            setShowModalMateri(false);
            setEditMateri(null);
          }}
          editMateri={editMateri}
        />
      )}
      {showAddLiveClass && (
        <TambahLiveClass
          show={showAddLiveClass}
          dataTopik={topik}
          dataSiswa={listSiswa}
          handleClose={() => {
            setShowAddLiveClass(false);
            getDataTopik();
          }}
        />
      )}
      {showModalTugas && (
        <TambahTugas
          show={showModalTugas}
          dataTopik={topik?.topik}
          dataSiswa={listSiswa}
          handleClose={() => {
            setEditTugas(null);
            setShowModalTugas(false);
          }}
          editTugas={editTugas}
          idMapel={id}
        />
      )}
      {showSortTopik && (
        <SortTopik
          kelasmapel_id={id}
          dataTopik={topik?.topik}
          handleClose={() => {
            setShowSortTopik(false);
            getDataTopik();
          }}
          show={showSortTopik}
        />
      )}
      {showShareKelas && (
        <ShareKelas
          show={showShareKelas}
          handleClose={() => {
            setShowShareKelas(false);
            setSelectedTopik([]);
          }}
          sekolahId={detailKelas?.kelasmapel_sekolahid}
          selectedTopik={selectedTopik}
          idMapel={id}
        />
      )}
      {showModalDiskusi && (
        <ModalDiskusi
          show={showModalDiskusi}
          handleClose={() => {
            setShowModalDiskusi(false);
            setEditDiskusi(null);
          }}
          dataSiswa={listSiswa}
          dataTopik={topik}
          editDiskusi={editDiskusi}
        />
      )}
      <div className="col-lg-12">
        {topik?.loading ? (
          <div className="text-center">
            <img src={PageLoader} alt="loader" height={80} width={60} />
          </div>
        ) : (
          <Accordion className="iq-accordion career-style faq-style">
            <ReactSortable
              onUpdate={() => (sortingRef.current = true)}
              list={topik?.topik}
              setList={handleSortTopik}
              disabled={!checkIsGuru(user?.user_roleid) || !sortingData}
              handle="#head-topik-card"
            >
              {topik?.topik?.map((item) => (
                <TopikCard
                  key={item?.topik_id}
                  dataTopik={item}
                  topik={item?.topic}
                  desc={item?.deskripsi}
                  files={item?.files}
                  code={item.topik_id}
                  dataDetail={item?.data_detail}
                  status={item.status}
                  handleEditTopik={() => {
                    setShowModalTopik(true);
                    setEditTopik(item.topik_id);
                  }}
                  handleEditMateri={(id_materi) => {
                    setShowModalMateri(true);
                    setEditMateri(id_materi);
                  }}
                  handleEditTugas={(id_tugas) => {
                    setShowModalTugas(true);
                    setEditTugas(id_tugas);
                  }}
                  handleEditDiskusi={(id_diskusi) => {
                    setShowModalDiskusi(true);
                    setEditDiskusi(id_diskusi);
                  }}
                  sortingData={sortingData}
                  handleChecked={handleChangeSelectedTopik}
                  checked={
                    selectedTopik?.filter((data) => data === item.topik_id)
                      ?.length > 0
                  }
                  listSiswa={listSiswa}
                  getDataTopik={getDataTopik}
                  showAction={showAction}
                  sekolahId={detailKelas?.kelasmapel_sekolahid}
                  idMapel={id}
                />
              ))}
            </ReactSortable>
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default Materi;

import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  getAbsensiKelas,
  getAbsensiSemester,
  getAbsensiSiswa,
  getJenisAbsensi,
} from "../../../redux/actions/absenAction";
import { getAnak, setAnak } from "../../../redux/actions/anakAction";
import { checkIsOrtu } from "../../../utils/roleUser";
import { Link } from "react-router-dom";

function AbsensiSiswa() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataAnak, selectedAnak } = useSelector(({ anak }) => anak);
  const { user } = useSelector(({ user }) => user);
  const [kelas, setKelas] = useState([]);
  const [semester, setSemester] = useState([]);
  const [jenisAbsensi, setJenisAbsensi] = useState([]);
  const [siswa, setSiswa] = useState([]);
  const [params, setParams] = useState({
    semester_id: "",
    kelasdetail_id: "",
    kehadiran_jenis_id: "",
    tanggal_mulai: "",
    tanggal_sampai: "",
  });
  function checkProperties(obj) {
    if (obj.semester_id === "" || obj.semester_id === null) return false;
    if (obj.kelasdetail_id === "" || obj.kelasdetail_id === null) return false;
    if (obj.kehadiran_jenis_id === "" || obj.kehadiran_jenis_id === null)
      return false;
    return true;
  }
  useEffect(() => {
    if (checkIsOrtu(user?.user_roleid)) {
      dispatch(getAnak()).then((res) => {
        if (
          (selectedAnak === null || selectedAnak === undefined) &&
          res?.value
        ) {
          dispatch(setAnak(res?.value[0]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);

  useEffect(() => {
    function getData() {
      let data = {};
      let data2 = {};
      if (checkIsOrtu(user?.user_roleid)) {
        data.userkelas_userid = selectedAnak?.user_id;
        data2.user_sekolah_id = selectedAnak?.user_sekolahid;
      }
      getAbsensiKelas(data).then((res) => setKelas(res));
      getJenisAbsensi(data2).then((res) => {
        setJenisAbsensi(res);
        if (res.length > 0) {
          setParams((pref) => ({
            ...pref,
            kehadiran_jenis_id: res[0].kehadiran_jenis_id,
          }));
        }
      });
    }
    getData();
  }, [selectedAnak, user]);
  useEffect(() => {
    setSemester([]);
    function getData() {
      let data = { kelasdetail_id: params.kelasdetail_id };
      if (checkIsOrtu(user?.user_roleid))
        data.user_sekolahid = selectedAnak.user_sekolahid;
      getAbsensiSemester(data).then((res) => setSemester(res));
    }
    if (params.kelasdetail_id) {
      getData();
    }
  }, [params.kelasdetail_id, selectedAnak, user]);
  useEffect(() => {
    function getData() {
      const data = params;
      if (checkIsOrtu(user?.user_roleid))
        data.userkelas_userid = selectedAnak?.user_id;
      getAbsensiSiswa(data).then((res) => {
        setSiswa(res);
      });
    }
    let check = checkProperties(params);
    if (check) getData();
  }, [params, selectedAnak?.user_id, user?.user_roleid]);

  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeParams = (value, name) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeAnak = (e) => {
    let val = e?.target?.value;
    let anak = dataAnak?.filter(
      (item) => parseInt(item.user_id) === parseInt(val)
    );
    if (anak) {
      return dispatch(setAnak(anak[0]));
    }
    dispatch(setAnak(null));
  };

  return (
    <div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex justify-content-between align-items-center w-100">
            <h4 className="card-title">Filter {t("kelas")}</h4>
            <Link
              to={`/`}
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            {checkIsOrtu(user?.user_roleid) ? (
              <div className="col-12 col-md-3">
                <label htmlFor="anak" className="control-label">
                  {t("anak")} :
                </label>
                <select
                  onChange={handleChangeAnak}
                  id="anak"
                  className="form-control form-select"
                  value={selectedAnak?.user_id}
                >
                  <option value="">
                    --{t("pilih_params", { params: t("siswa") })}--
                  </option>
                  {dataAnak?.map((item) => (
                    <option key={item.user_id} value={item.user_id}>
                      {item.user_nama}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            <div
              className={`col-12 ${
                checkIsOrtu(user?.user_roleid) ? "col-md-2" : "col-md-3"
              }`}
            >
              <label htmlFor="kelasdetail_id" className="control-label">
                {t("kelas")} :
              </label>
              <select
                id="kelasdetail_id"
                className="form-control form-select"
                name="kelasdetail_id"
                onChange={handleChange}
                value={params.kelasdetail_id}
              >
                <option value="">
                  --{t("pilih_params", { params: t("kelas") })}--
                </option>
                {kelas?.map((e) => (
                  <option value={e.kelasdetail_id} key={e.kelasdetail_id}>
                    {e.kelasdetail_nama}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`col-12 ${
                checkIsOrtu(user?.user_roleid) ? "col-md-2" : "col-md-3"
              }`}
            >
              <label htmlFor="semester" className="control-label">
                Semester :
              </label>
              <select
                className="form-control form-select"
                name="semester_id"
                id="semester_id"
                onChange={handleChange}
                value={params.semester_id}
              >
                <option value="">
                  --{t("pilih_params", { params: "Semester" })}--
                </option>
                {semester?.map((e) => (
                  <option value={e.semester_id} key={e.semester_id}>
                    Semester {e.semester_nama}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`col-12 ${
                checkIsOrtu(user?.user_roleid) ? "col-md-5" : "col-md-6"
              }`}
            >
              <label htmlFor="tanggal_mulai" className="control-label">
                {t("tanggal")} :
              </label>
              <div className="row">
                <div className="col-6">
                  <input
                    type="date"
                    className="form-control"
                    id="tanggal_mulai"
                    value={params.tanggal_mulai}
                    name="tanggal_mulai"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="tanggal_mulai"
                    className="font-size-12 text-danger m-0 ml-1"
                  >
                    {t("tgl_mulai")}
                  </label>
                </div>
                <div className="col-6">
                  <input
                    type="date"
                    className="form-control"
                    id="tanggal_sampai"
                    value={params.tanggal_sampai}
                    name="tanggal_sampai"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="tanggal_sampai"
                    className="font-size-12 text-danger m-0 ml-1"
                  >
                    {t("tgl_akhir")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">{t("absensi")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <ul
            className="nav nav-pills-walas justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            {jenisAbsensi?.map((item) => (
              <li className="nav-item" key={item.kehadiran_jenis_id}>
                <button
                  className={`nav-link-walas border-0 ${
                    params.kehadiran_jenis_id === item.kehadiran_jenis_id
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    handleChangeParams(
                      item.kehadiran_jenis_id,
                      "kehadiran_jenis_id"
                    );
                  }}
                >
                  {item.kehadiran_jenis_nama}
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content mt-3" id="myTabContent-5">
            {params.kehadiran_jenis_id ? (
              <div className="table-responsive">
                <table
                  id="datatable"
                  className="table table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th scope="col" width="5%" className="text-center">
                        No
                      </th>
                      <th scope="col" width="30%">
                        {t("tanggal")}
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-primary"
                      >
                        {t("hadir")}
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-success"
                      >
                        {t("izin")}
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-danger"
                      >
                        Alpa
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-warning"
                      >
                        {t("sakit")}
                      </th>
                      <th scope="col" width="40%">
                        {t("catatan_guru")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {siswa?.length <= 0 ? (
                      <tr className="text-center">
                        <td colSpan={7}>{t("data_kosong")}</td>
                      </tr>
                    ) : (
                      siswa?.map((item, idx) => (
                        <tr key={item.kehadiran_id}>
                          <td className="text-center">{idx + 1}</td>
                          <td
                            className="border-white"
                            style={{
                              background: "#1bbc9b",
                              color: "white",
                            }}
                          >
                            <b>
                              {moment(
                                item?.kehadiran_tanggal.substr(0, 10)
                              ).format("DD MMMM YYYY")}
                            </b>
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "H"}
                                disabled
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "I"}
                                disabled
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "A"}
                                disabled
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "S"}
                                disabled
                              />
                            </div>
                          </td>
                          <td>{item.kehadiran_keterangan}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="py-2">
                <p className="text-center">
                  {params.kelasdetail_id === ""
                    ? t("pilih_kelas_dahulu")
                    : params.semester_id === ""
                    ? t("pilih_semester_dahulu")
                    : params.tanggal_mulai === ""
                    ? t("pilih_tgl_dahulu")
                    : params.kehadiran_jenis_id === ""
                    ? t("pilih_jenis_dahulu")
                    : ""}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbsensiSiswa;
